<template>
  <AppLayout>
    <ViewWrapper
      title="workspaces"
      :breadcrumbs="breadcrumbs"
      :tab="tab"
      :tabs="_tabs"
      @quickHelp="showQuickHelp"
    >
      <!-- header -->

      <template #header-actions>
        <BaseButton
          v-if="showAddWorkspaces"
          icon="eva-plus"
          label="add workspace"
          class="q-ml-md"
          @click="addWorkspace"
        />
      </template>

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->

      <!-- child routes -->

      <router-view />

      <!-- ... -->
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";

export default {
  name: "Workspaces",

  components: { AppLayout, ViewWrapper, QuickHelp },

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "workspaces",
          route: "workspaces-overview",
        },
        {
          id: this.$nano.id(),
          label: "",
          route: "",
        },
      ],
      tabs: [
        {
          id: this.$nano.id(),
          label: "overview",
          icon: "mdi-view-dashboard-outline",
          route: "workspaces-overview",
          access: false,
          accessLabel: "Overview",
        },
        {
          id: this.$nano.id(),
          label: "browse",
          icon: "eva-folder-outline",
          route: "workspaces-browse",
          access: false,
          accessLabel: "Manage Workspace",
        },
        // {
        //   id: this.$nano.id(),
        //   label: "reports",
        //   icon: "eva-pie-chart-outline",
        //   route: "workspaces-reports",
        // },
        // {
        //   id: this.$nano.id(),
        //   label: "security",
        //   icon: "eva-shield-outline",
        //   route: "workspaces-security",
        // },
        {
          id: this.$nano.id(),
          label: "trash",
          icon: "eva-trash-2-outline",
          route: "workspaces-trash",
          access: false,
          accessLabel: "Manage Workspace",
        },
      ],
      tab: "workspaces-overview",
      quickHelp: false,
    };
  },

  computed: {
    showAddWorkspaces() {
      if (this.$store.state.profileMenus.length)
        return this.$store.state.profileMenus.find((row) => row.workspaces)
          .Menu["Manage Workspace"];

      return false;
    },

    _tabs() {
      if (this.$store.state.profileMenus.length) {
        this.tabs.forEach((tab) => {
          if (!tab.access) {
            tab.access = this.$store.state.profileMenus.find(
              (row) => row.workspaces
            )?.Menu[tab.accessLabel];
          }
        });
      }
      return this.tabs.filter((tab) => tab.access);
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.tab = this.$route.name;
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
  },

  methods: {
    addWorkspace() {
      this.$router.push({ name: "workspace-builder" });
    },

    showQuickHelp() {
      this.quickHelp = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
